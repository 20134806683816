@charset "UTF-8";
/*Import Core*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: flex; }

body,
html {
  width: 100%;
  height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }

ol,
ul {
  list-style: none; }

hr {
  border: 0;
  height: 1px;
  background: #ccc; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box; }

html,
input,
select,
textarea,
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Montserrat', sans-serif; }

* {
  font-family: Montserrat, 'Avenir LT Std', 'Helvetica Nueue', sans-serif; }

html {
  font-size: 62.5%; }

body {
  background-color: #FFFFFF; }

.container {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%; }

.text_left {
  text-align: left; }

.text_center {
  text-align: center; }

.text_right {
  text-align: right; }

.dash,
.content {
  height: 100%; }

.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  width: 60rem;
  height: 60rem;
  text-align: center;
  padding: 6rem;
  align-self: center;
  margin: auto;
  border-radius: 8px;
  background-color: #FBFCFD; }
  .login_form img {
    margin-bottom: 3rem; }
  .login_form .form {
    width: 80%;
    height: 100%; }
    .login_form .form input[type='text'],
    .login_form .form input[type='password'] {
      width: 100%;
      padding: 2rem 1rem;
      border: 1.5px solid #094063;
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-weight: normal;
      outline-color: #094063;
      outline-style: unset;
      border-radius: 4px;
      font-size: 1.6rem; }
    .login_form .form input[type='submit'] {
      width: 100%;
      font-weight: 800;
      padding: 2rem;
      font-size: 1.6rem;
      margin-top: 3rem;
      margin-bottom: 2rem;
      cursor: pointer; }

.dashboard-container {
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box; }

.column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }

#left {
  flex-shrink: 0; }

.top-left {
  flex-shrink: 0; }

.top-right {
  flex-shrink: 0;
  display: inline-flex; }

.bottom {
  flex-grow: 1;
  overflow-y: auto; }

.dashboard {
  display: flex;
  flex-direction: row;
  width: 100%; }

.sidebar {
  width: 20%;
  height: 100%;
  background-color: #FFFFFF;
  font-size: 1.6rem;
  min-width: 25rem; }
  .sidebar ul {
    width: 100%; }

.sidebar__top {
  height: 8rem;
  display: flex;
  padding: 1.5rem 2rem;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid #f8f8f8; }

.sidebar__top--accounts {
  flex-direction: column;
  align-items: self-start; }
  .sidebar__top--accounts p {
    color: #094063;
    font-weight: 800;
    margin-bottom: 1rem; }

.sidebar__bottom {
  display: flex;
  padding: 1rem;
  height: 10rem;
  flex-direction: column;
  background-color: #FFFFFF; }

.sidebar--space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.sidebar--accounts {
  background-color: #FFFFFF; }

.dashboard__menu {
  padding: 2rem;
  display: flex;
  border-left: 5px solid transparent;
  align-items: center;
  padding-left: 1.5rem; }
  .dashboard__menu a {
    text-decoration: none;
    color: #094063;
    font-style: normal;
    font-weight: 600; }
  .dashboard__menu .fas {
    margin-right: 2rem;
    color: #094063;
    font-size: 2rem;
    width: 3rem; }

.menu-active {
  border-left: 5px solid #094063;
  background: rgba(9, 64, 99, 0.06); }

.dashboard-content-background {
  background-color: #F8F8F8;
  padding: 0 2rem 2rem 2rem; }

.header {
  height: 10rem;
  background-color: #FFFFFF;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2rem solid #f8f8f8; }

.header__left {
  display: none;
  align-items: center; }
  .header__left .toggle-nav {
    display: none; }

.header__title h2 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  color: #094063; }

.header__right {
  display: flex;
  align-items: center;
  justify-content: center; }
  .header__right button {
    border-radius: 4px;
    font-size: 1.6rem;
    width: 10rem;
    height: 4rem;
    font-style: normal;
    font-weight: normal;
    background-color: #094063;
    color: #FFFFFF;
    border: 0;
    margin: 0 1rem; }
  .header__right .header__notif button {
    width: 2rem;
    height: 2rem; }
  .header__right .header__notif .notif {
    border: 0;
    background-color: #FFFFFF;
    color: #094063; }
  .header__right .header__search {
    width: 16rem;
    height: 4rem; }
    .header__right .header__search input {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      border: 1px solid #094063;
      padding: 1rem; }

.badge {
  position: relative; }

.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 0.8rem;
  background: #d72c2c;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  font-style: normal;
  font-weight: normal; }

.active {
  display: flex;
  flex-direction: column; }

.fas {
  color: #094063; }

.rdtPicker {
  width: 36rem; }

.dashboard-card {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(81, 153, 211, 0.04);
  border-radius: 4px;
  color: #094063;
  padding: 2rem; }

.help-section {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .help-section {
      flex-wrap: wrap; } }
  .help-section .faq-content {
    width: 65%;
    padding: 3rem 3rem 0rem 3rem; }
    @media screen and (max-width: 768px) {
      .help-section .faq-content {
        width: 100%;
        margin-bottom: 2rem; } }
    .help-section .faq-content h1 {
      color: #094063;
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 16px; }
    .help-section .faq-content p.question {
      color: #9c9c9c;
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px; }
  .help-section .dash-contact {
    width: 35%;
    margin-left: 25px;
    height: 100%;
    padding: 3rem; }
    @media screen and (max-width: 768px) {
      .help-section .dash-contact {
        width: 100%;
        margin-left: 0;
        margin-bottom: 2rem; } }
    .help-section .dash-contact h1 {
      color: #094063;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 26px; }
    .help-section .dash-contact p {
      color: #212121;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px; }
    .help-section .dash-contact .social-section {
      margin: 3rem 0; }
      .help-section .dash-contact .social-section p {
        margin-bottom: 5px; }
      .help-section .dash-contact .social-section a {
        color: #107bc0;
        text-decoration: underline; }
  .help-section .accordion {
    position: relative;
    margin: 60px auto; }
  .help-section [id*='open-accordion'],
  .help-section [id*='close-accordion'] {
    background: #fff;
    border-bottom: 1px solid #c4c4c4;
    line-height: 40px;
    padding: 2rem 0 1rem;
    display: block;
    margin: 0 auto;
    position: relative; }
  .help-section [id*='close-accordion'] {
    display: none; }
  .help-section .accordion a {
    color: #107bc0;
    font-weight: normal;
    font-size: 15px;
    text-decoration: none;
    text-shadow: none; }
  .help-section [id*='open-accordion']:after,
  .help-section [id*='close-accordion']:after {
    content: '❯';
    position: absolute;
    right: 13px;
    top: 20px;
    font-size: 14px;
    z-index: 999;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg); }
    @media screen and (max-width: 768px) {
      .help-section [id*='open-accordion']:after,
      .help-section [id*='close-accordion']:after {
        display: none; } }
  .help-section .target-fix {
    display: block;
    top: 0;
    left: 0;
    position: fixed; }
  .help-section .accordion-content {
    background: #fff;
    height: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease; }
    .help-section .accordion-content p {
      color: #212121;
      font-size: 16px;
      line-height: 22px; }
  .help-section .accordion span:target ~ .accordion-content {
    display: block;
    height: auto;
    padding-bottom: 25px;
    padding-top: 10px; }
  .help-section .accordion span:target ~ [id*='close-accordion'] {
    display: block; }
  .help-section .accordion span:target ~ [id*='open-accordion'] {
    display: none; }
  .help-section .accordion span:target ~ [id*='close-accordion']:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg); }

.dashboard-card__title-text {
  font-size: 1.8rem;
  color: #212121;
  margin-bottom: 2rem; }

.dashboard-card__body-text, .signup__body-text {
  font-size: 1.6rem;
  color: #212121;
  margin-bottom: 2rem; }

.dashboard-card__text--left-align {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.dashboard__primary-btn, .dashboard__primary-btn--full-width {
  background-color: #094063;
  color: white;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.4s ease-in-out; }
  a.dashboard__primary-btn, a.dashboard__primary-btn--full-width {
    text-decoration: none;
    color: #094063; }
  .dashboard__primary-btn:hover, .dashboard__primary-btn--full-width:hover {
    background-color: #175171;
    color: #FFFFFF; }

.dashboard_renew_btn {
  background-color: #107ac1;
  color: white;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px; }

.dashboard_custom_renew_btn {
  background-color: transparent;
  color: #107ac1;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #107ac1;
  transition: 0.3s ease-in-out; }
  .dashboard_custom_renew_btn:hover {
    background: #107ac1;
    color: #fff; }

.dashboard__primary-btn:disabled, .dashboard__primary-btn--full-width:disabled {
  background-color: #8FA8B8; }

.dashboard__secondary-btn, .dashboard__secondary-btn--full-width, .dashboard__secondary-btn__danger, .dashboard__secondary-btn__green {
  width: fit-content;
  border: 1.5px solid #094063;
  background-color: #FFFFFF;
  border-radius: 4px;
  color: #094063;
  font-size: 1.2rem;
  padding: 1rem;
  cursor: pointer;
  font-weight: 800;
  transition: 0.4s ease-in-out; }
  a.dashboard__secondary-btn, a.dashboard__secondary-btn--full-width, a.dashboard__secondary-btn__danger, a.dashboard__secondary-btn__green {
    text-decoration: none;
    color: #094063; }
  .dashboard__secondary-btn:hover, .dashboard__secondary-btn--full-width:hover, .dashboard__secondary-btn__danger:hover, .dashboard__secondary-btn__green:hover {
    background-color: #175171;
    color: #FFFFFF; }

.dashboard__primary-btn--full-width {
  width: 100%;
  margin-bottom: 25px; }

.dashboard__secondary-btn--full-width {
  width: 100%;
  margin-bottom: 25px; }

.dashboard__secondary-btn__danger {
  border: 1.5px solid #AB0404;
  color: #AB0404;
  transition: 0.4s ease-in-out; }
  .dashboard__secondary-btn__danger:hover {
    background-color: #B21A1A;
    color: #FFFFFF; }

.dashboard__secondary-btn__green {
  border: 1.5px solid #219653;
  color: #219653;
  transition: 0.4s ease-in-out; }
  .dashboard__secondary-btn__green:hover {
    background-color: #35945e;
    color: #FFFFFF; }

.dashboard-table-wrap {
  display: flex;
  flex-direction: column; }

.dashboard-table {
  width: 100%; }

.dashboard-table-item {
  min-height: 5rem;
  font-size: 1.4rem;
  text-align: left;
  padding: 2rem;
  color: #212121;
  vertical-align: middle; }

.dashboard-table-item__header {
  background-color: rgba(81, 153, 211, 0.04);
  font-weight: 800; }

.dashboard-table-item__body {
  font-weight: 600;
  color: #333333; }

.dashboard-table-item__link-item, .dashboard-table-item__link-item--underlined {
  text-decoration: none;
  color: #094063;
  width: 100%;
  display: flex; }

.dashboard-table-item__link-item--underlined {
  text-decoration: underline; }

.dashboard-table tr:nth-child(odd) {
  background-color: rgba(81, 153, 211, 0.03); }

.dashboard-table tr:hover {
  background-color: #F9FAFC; }

.dashboard-control {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center; }

.dashboard-control-item {
  display: flex;
  border: 1px solid rgba(9, 64, 99, 0.2);
  border-radius: 4px;
  padding: 1rem;
  font-size: 1.6rem; }
  .dashboard-control-item button {
    border: 0;
    font-size: 1.6rem;
    cursor: pointer;
    color: #094063; }

.dashboard-control__button {
  font-weight: 800;
  font-size: 1.6rem;
  min-width: 14rem; }

.dashboard-table-overflow {
  overflow-x: scroll; }

.profile-input-wrap {
  margin-right: 4rem; }

.profile-item-wrapper, .dashboard-form-item {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  justify-content: space-between;
  line-height: 2.4rem;
  overflow-wrap: break-word; }

.profile-item__label {
  font-size: 1.4rem;
  font-weight: 600;
  color: #4F4F4F; }

.profile-item__text, .dashboard-form__input {
  font-size: 1.8rem;
  font-weight: 800;
  color: #212121;
  margin-top: 1rem; }

.dashboard-form__input {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 1rem 1.2rem;
  width: 36rem;
  outline: none;
  font-weight: 600;
  font-size: 1.6rem; }

.dashboard-form__input:focus {
  border: 1px solid #5199D3; }

.dashboard-form {
  display: flex;
  flex-direction: column; }
  .dashboard-form input[type='radio'],
  .dashboard-form input[type='checkbox'] {
    margin-right: 1rem;
    font-size: 2rem; }

.dashboard-form-item-wrapper {
  display: flex; }

.dashboard-form__btn {
  margin-top: 4rem;
  font-size: 1.6rem;
  font-weight: 800;
  align-self: end; }

.dashboard-form__small-text {
  color: #6d6d6d;
  font-size: 1.4rem;
  padding: 1rem; }

.dashboard-form__checklist-wrap {
  color: #212121;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  width: 36rem;
  flex-direction: column; }

.dashboard-form-item__radio-check-btn {
  display: block;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
  text-align: left; }
  .dashboard-form-item__radio-check-btn label {
    padding-right: 6rem;
    text-align: right;
    width: 100%;
    display: block;
    color: #212121;
    cursor: pointer;
    position: relative;
    z-index: 2;
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 4px; }
    .dashboard-form-item__radio-check-btn label:before {
      width: 100%;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: rgba(137, 203, 240, 0.08);
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 0;
      z-index: -1; }
    .dashboard-form-item__radio-check-btn label:after {
      width: 20px;
      height: 20px;
      content: '';
      border: 2px solid #094063;
      background-color: #fff;
      background-image: url(./img/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 68%;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
  .dashboard-form-item__radio-check-btn input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(75, 56, 1);
    opacity: 1; }
  .dashboard-form-item__radio-check-btn input:checked ~ label:after {
    background-color: #094063;
    border-color: #094063; }
  .dashboard-form-item__radio-check-btn input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden; }

#rearrange {
  display: flex;
  flex-direction: row-reverse; }

.dashboard-form-item__check-btn label:after {
  border-radius: 10%; }

.dashboard-form__select {
  -webkit-appearance: none;
  background: url(./img/down_chevron.svg) no-repeat 95% 50%; }

.profile__img {
  height: 22rem;
  width: 22rem;
  border-radius: 4px; }

.profile-item {
  width: 21rem;
  margin-right: 2rem; }

.profile-item-style-2 {
  padding-right: 2rem; }

.profile-detail-wrap {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  padding-top: 0; }

.profile-detail-wrap__layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem; }

.profile-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }

.dashboard-btn-section {
  position: relative;
  justify-content: space-between;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  padding: 2rem;
  background-color: #FFFFFF; }
  .dashboard-btn-section p {
    color: #212121;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    max-width: 59rem; }
  .dashboard-btn-section button {
    height: 45px; }
    .dashboard-btn-section button:nth-child(1) {
      margin-right: 2rem; }
  .dashboard-btn-section .transfer-btn {
    position: absolute;
    right: 18rem; }
  .dashboard-btn-section .edit-btn {
    position: absolute;
    right: 35rem; }

.alert__banner {
  padding: 2rem 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .alert__banner h3 {
    font-size: 1.7rem;
    margin-bottom: 0.8rem; }
  .alert__banner p {
    font-size: 1.2rem; }
  .alert__banner .dismiss {
    font-weight: bolder;
    cursor: pointer; }
    .alert__banner .dismiss .fa {
      color: white; }

.alert__banner.success {
  background: #449802; }

.alert__banner.error {
  background: #AB0404; }

.content-switcher__btn_right-curve {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px; }

.content-switcher__btn_left-curve {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px; }

.content-switcher,
.plan_duration_btn_wrapper {
  max-width: 403px;
  display: flex;
  border: 2px solid #094063;
  border-radius: 4px;
  margin: 2rem 0; }

.dashboard-content-switcher__btn {
  font-size: 1.6rem;
  color: #212121;
  border: 0;
  background-color: #f9fafc;
  width: 100%;
  font-weight: 800;
  padding: 1rem;
  cursor: pointer; }

.content-switcher__btn_active {
  font-weight: 800;
  background-color: #094063;
  color: #fff; }

.plan-link {
  color: #107bc0;
  font-size: 1.6rem;
  margin-bottom: 2rem; }

.plan-pricing-wrapper {
  display: flex;
  flex-direction: column; }

.plan-item {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-width: 40rem;
  margin-right: 3rem;
  margin-bottom: 4rem; }

.price-wrap {
  display: flex;
  padding-bottom: 2rem;
  flex-wrap: wrap; }

.plan-item-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0; }

.plan-pricetag {
  font-size: 2.4rem;
  color: #27ae60;
  font-weight: 800; }

.pricetag-duration {
  font-size: 1.4rem;
  color: #212121;
  margin-left: 1rem;
  font-weight: 800; }

.plan-btn {
  margin-top: 2rem; }

.plan-item_rb {
  border-bottom: 5px solid #89cbf0;
  padding-bottom: 2rem; }

.plan-item_ad {
  border-bottom: 5px solid #5199d3;
  padding-bottom: 2rem; }

.empty-state-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center; }

.empty-state-btn-wrap {
  width: 30%;
  display: flex;
  justify-content: space-between; }

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

/* Modal Content/Box */
.modal-content {
  background-color: #FFFFFF;
  margin: 10% auto;
  padding: 2rem;
  max-width: 60rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column; }
  .modal-content h2 {
    font-size: 1.8rem;
    margin: 0rem 2rem 3rem 2rem; }
  .modal-content h4 {
    font-size: 16px;
    text-align: center;
    margin-top: 3rem; }
  .modal-content h6 {
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    padding: 0 1rem 2rem; }
    .modal-content h6:nth-child(1) {
      margin-bottom: 1.4rem; }
    .modal-content h6:nth-child(2) {
      border-bottom: 2px solid #ccdbe9;
      margin-bottom: 2rem; }
  .modal-content form p {
    margin: 1rem; }
  .modal-content form [type='radio']:checked,
  .modal-content form [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px; }
  .modal-content form [type='radio']:checked + label,
  .modal-content form [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #094063;
    padding-left: 4rem;
    padding-bottom: 1rem;
    width: 100%; }
  .modal-content form [type='radio']:checked + label:before,
  .modal-content form [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 23px;
    height: 23px;
    border: 1px solid #094063;
    border-radius: 100%;
    background: #fff; }
  .modal-content form [type='radio']:checked + label:after,
  .modal-content form [type='radio']:not(:checked) + label:after {
    content: '';
    width: 18px;
    height: 18px;
    background: #094063;
    position: absolute;
    top: 3.5px;
    left: 3.5px;
    border-radius: 100%;
    transition: all 0.2s ease; }
  .modal-content form [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0); }
  .modal-content form [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  .modal-content form textarea {
    display: inline-block;
    background: #F2F2F2;
    width: 100%;
    padding: 2rem;
    border-radius: 10px;
    border: 0;
    font-size: 14px;
    margin-top: 1rem; }
    .modal-content form textarea:focus {
      outline: none; }
    .modal-content form textarea::placeholder {
      color: #4F4F4F;
      font-size: 14px; }
  .modal-content form button {
    background: #094063;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    width: 100%;
    max-width: 200px;
    margin: 2rem auto 0;
    height: 45px;
    border: 0;
    border-radius: 5px;
    color: #fff; }
  .modal-content form button:disabled,
  .modal-content form button[disabled] {
    background-color: #cccccc;
    color: #fff;
    width: 28rem;
    height: 45px;
    border: 0;
    border-radius: 5px;
    pointer-events: none; }
    .modal-content form button:disabled:hover,
    .modal-content form button[disabled]:hover {
      background-color: #cccccc; }
  .modal-content .wrong-infos {
    margin: 3rem 0; }
    .modal-content .wrong-infos p {
      color: #212121;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 2rem; }
    .modal-content .wrong-infos span {
      text-decoration: underline; }
    .modal-content .wrong-infos button {
      background: transparent;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-size: 14px;
      align-items: center;
      width: 100%;
      max-width: 200px;
      margin: 2rem auto;
      height: 45px;
      border: 2px solid #094063;
      border-radius: 5px;
      color: #094063;
      transition: 0.4s ease-in-out; }
      .modal-content .wrong-infos button:hover {
        background: #094063;
        color: #fff; }
    .modal-content .wrong-infos .reactivate-btn {
      display: flex;
      justify-content: center;
      max-width: 44rem;
      margin: auto; }
      .modal-content .wrong-infos .reactivate-btn button:nth-child(1) {
        background: #094063;
        color: #fff; }
      .modal-content .wrong-infos .reactivate-btn button:nth-child(2) {
        background: transparent;
        color: #107bc0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        border: 0; }
  .modal-content .for-deactivate {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 2rem auto; }
    .modal-content .for-deactivate p {
      color: #212121;
      margin-top: 2rem;
      font-size: 16px; }
    .modal-content .for-deactivate button {
      background: #094063;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-size: 14px;
      align-items: center;
      width: 100%;
      max-width: 200px;
      margin: 2rem auto 0;
      height: 45px;
      border: 0;
      border-radius: 5px;
      color: #fff; }
    .modal-content .for-deactivate img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 100%;
      margin: 1rem auto 3rem; }
  .modal-content .edit-options h5 {
    color: #4F4F4F;
    font-size: 15px;
    margin-bottom: 3rem; }
  .modal-content .edit-options .edit-form {
    display: flex;
    justify-content: space-between; }
    .modal-content .edit-options .edit-form-section {
      width: 100%; }
      .modal-content .edit-options .edit-form-section:nth-child(1) {
        padding-right: 2rem; }
    .modal-content .edit-options .edit-form .push-bottom {
      margin-bottom: 4rem; }
    .modal-content .edit-options .edit-form label {
      display: block;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #827c7c;
      margin-bottom: 1rem; }
    .modal-content .edit-options .edit-form input {
      background: #fcfcfc;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      text-indent: 1rem;
      border: 1px solid #ece5e5; }
      .modal-content .edit-options .edit-form input:focus {
        outline: none; }
  .modal-content .invoice-options h5 {
    color: #4F4F4F;
    font-size: 18px;
    margin: 3rem; }
  .modal-content .invoice-options-list {
    margin-bottom: 5rem; }
  .modal-content .invoice-options .radio {
    margin: 0.5rem;
    margin-bottom: 1rem;
    color: #000;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px; }
    .modal-content .invoice-options .radio label.radio-label {
      display: block;
      width: 100%;
      color: #212121; }
      .modal-content .invoice-options .radio label.radio-label .plans-monthly {
        display: flex;
        justify-content: space-between; }
        .modal-content .invoice-options .radio label.radio-label .plans-monthly p {
          margin: 0.5rem 0; }
    .modal-content .invoice-options .radio input[type='radio'] {
      position: absolute;
      opacity: 0; }
      .modal-content .invoice-options .radio input[type='radio']:checked + .radio-label:before {
        background-color: #094063;
        box-shadow: inset 0 0 0 4px #094063; }
      .modal-content .invoice-options .radio input[type='radio']:focus + .radio-label:before {
        outline: none;
        border-color: #094063; }
      .modal-content .invoice-options .radio input[type='radio'] + .radio-label:empty:before {
        margin-right: 0; }
  .modal-content .enrollee-list-content h2 {
    font-size: 16px;
    padding-left: 2rem;
    margin-top: 3rem; }
  .modal-content .enrollee-list-content fieldset {
    border: none; }
  .modal-content .enrollee-list-content .checkbox-wrapper {
    margin: 2.5rem 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px; }
    .modal-content .enrollee-list-content .checkbox-wrapper label {
      width: 100%; }
    .modal-content .enrollee-list-content .checkbox-wrapper-display {
      display: flex;
      justify-content: space-between; }
      .modal-content .enrollee-list-content .checkbox-wrapper-display p {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #212121;
        margin: 0 0 0.5rem 0; }
    .modal-content .enrollee-list-content .checkbox-wrapper p {
      font-size: 16px;
      line-height: 22px;
      color: #212121;
      margin: 0 0 0.5rem 0; }
  .modal-content .enrollee-list-content .checkbox {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    box-sizing: border-box; }
  .modal-content .enrollee-list-content .checkbox + .label {
    cursor: pointer;
    display: inline-block;
    padding-left: 2.85rem;
    position: relative; }
  .modal-content .enrollee-list-content .checkbox + .label:before {
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 20px; }
  .modal-content .enrollee-list-content .checkbox:checked + .label:before {
    background-color: #107ac1;
    border-color: #107ac1; }
  .modal-content .enrollee-list-content .checkbox:checked + .label:after {
    content: '\2713';
    color: #fff;
    display: inline-block;
    left: 3px;
    position: absolute;
    top: 1px; }
  .modal-content .enrollee-list-content .checkbox:focus + label::before {
    box-shadow: 0 0 0 2px rgba(21, 156, 228, 0.7); }

/* The Close Button */
.modal-close {
  color: #4F4F4F;
  font-size: 28px;
  font-weight: bold;
  align-self: flex-end; }

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

.modal-link-item, .dashboard-card__link-item {
  color: #107bc0; }

.modal-header {
  background-color: rgba(9, 64, 99, 0.06);
  padding: 1rem; }

.payment-freq-modal-wrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 4rem;
  margin: 2rem 0; }

.payment-freq-modal-wrap__small-text {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 1rem; }

.dashboard-tab {
  justify-content: end; }

.dashboard-tab__button {
  min-width: 15rem;
  border: 0;
  padding: 1rem;
  font-size: 1.6rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 2rem;
  background-color: #f8f8f8;
  cursor: pointer;
  transition: 0.4s ease-in-out; }

.dashboard-tab__button:hover {
  background-color: #175171;
  color: #FFFFFF; }

.dashboard-tab__button-active {
  font-weight: 800;
  background-color: #094063;
  color: #FFFFFF; }

.modal-tabcontent {
  display: none;
  flex-direction: column; }

.overview-card-layout {
  display: flex;
  flex-direction: column; }

.overview-card-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap; }

.overview-card {
  width: 32%;
  display: flex;
  justify-content: space-between;
  color: #094063;
  margin-bottom: 2rem;
  height: 14rem; }

.dashboard-card__icon {
  align-self: flex-end; }

.dashboard-card__link-item {
  font-size: 1.6rem;
  margin-top: 2rem; }

.dashboard-card__data-text {
  font-size: 2.2rem;
  font-weight: 800; }

.dashboard-cart-container {
  flex-direction: column;
  width: 100%;
  background: white;
  display: flex;
  top: 0;
  position: sticky;
  border-bottom: 2rem solid #f8f8f8; }

.dashboard-cart-header {
  display: flex;
  width: 100%;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  .dashboard-cart-header p,
  .dashboard-cart-header h4 {
    margin-bottom: 0; }

.dashboard-cart-body {
  border-top: 1px solid rgba(9, 64, 99, 0.06);
  padding: 2rem;
  display: flex;
  width: 100%;
  flex-direction: column; }

.dashboard-cart-body__list-group-title {
  font-size: 1.2rem;
  color: #c4c4c4;
  padding-bottom: 1rem;
  text-transform: uppercase; }

.dashboard-cart-body__text {
  font-size: 1.6rem;
  color: #212121; }

.dashboard-cart-body__list-group {
  margin-bottom: 2rem; }

.dashboard-cart-body__list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; }

.dashboard-cart-clear {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }

.dashboard-cart__ghost-button {
  padding: 1rem;
  cursor: pointer;
  font-weight: 800;
  font-size: 1.6rem;
  background-color: #fff;
  border: 0;
  color: #094063; }

.dashboard-cart__ghost-button:hover {
  background-color: #175171;
  color: #FFFFFF;
  border-radius: 4px; }
  .dashboard-cart__ghost-button:hover .fas {
    color: #FFFFFF; }

.dashboard-cart-body__list {
  display: flex;
  width: 100%;
  flex-direction: column; }

.dashboard-cart__extension-text {
  color: #333;
  font-size: 1.4rem;
  margin: 1rem 0;
  font-weight: 500; }

.dashboard-cart__extension-button {
  margin-left: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: 0;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  background-color: #f9fafc;
  color: #333; }
  .dashboard-cart__extension-button .fas {
    color: #333; }

.dashboard-cart__extension-button:hover {
  color: #FFFFFF;
  background-color: #333; }
  .dashboard-cart__extension-button:hover .fas {
    color: #FFFFFF; }

.select-account-wrap {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 90rem;
  align-items: center;
  text-align: center; }

.new-logo {
  max-width: 20rem; }

.web-header__text {
  font-size: 4.8rem;
  font-weight: 900;
  line-height: 6rem;
  color: #107bc0; }

.web-header__text_small {
  font-size: 2rem;
  font-weight: 500;
  line-height: 4rem;
  color: #4f4f4f;
  max-width: 50rem; }

.select-account-card {
  display: flex; }
  .select-account-card .dashboard-card {
    width: 20rem;
    height: 25rem;
    cursor: pointer;
    box-shadow: 0px 2px 2px #e6f0f9;
    border: 1px solid #f9fafc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1.5rem; }
    .select-account-card .dashboard-card img {
      margin-bottom: 4rem; }
    .select-account-card .dashboard-card:hover .dashboard-card__body-text, .select-account-card .dashboard-card:hover .signup__body-text {
      color: #107bc0; }

.extension-selector-wrapper {
  width: 100%;
  margin-bottom: 1rem; }
  .extension-selector-wrapper .extension-count {
    font-size: 1.2rem;
    text-align: end;
    padding-right: 1rem;
    color: #084062;
    width: 100%; }

.plan-expiry-notice__header-text {
  color: #107ac1;
  font-size: 4.5rem;
  line-height: 5.5rem;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  margin-bottom: 25px; }

.plan-expiry-notice__body-text {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #333333;
  text-align: center;
  margin-bottom: 25px; }

.plan-number-selection {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.plan-number-selection__input {
  width: 45px;
  height: 4rem;
  padding: 0.5rem;
  border: 1px solid #C0E5E5;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center; }

.cart__total__line-item, .cart__total__line-item--padded {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; }

.cart__total__line-item--padded {
  padding: 30px 0; }

.enrollee-list__item {
  margin-top: 15px;
  padding: 5px 0;
  border-bottom: solid 1px #C4C4C4; }

.details-summary {
  padding: 10px 0;
  font-size: 1.4rem;
  margin-bottom: 2rem; }

.signup__body-text {
  margin-bottom: 0; }

/*------------------------------------- mobile responsive baby------------------------------------ */
@media screen and (max-width: 768px) {
  .active {
    display: none; }
  .sidebar {
    font-size: 1.4rem;
    min-width: 20rem; }
  .dashboard__menu .fas {
    font-size: 1.6rem;
    width: 2rem; }
  .header {
    padding: 2rem; }
  .header__left {
    display: flex;
    align-items: center; }
    .header__left .toggle-nav {
      display: flex;
      color: #094063;
      font-size: 2rem;
      border: none;
      margin-right: 1rem;
      background-color: #FFFFFF; }
  .header__title h2 {
    font-size: 1.8rem; }
  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header__right button {
      border-radius: 3px;
      font-size: 1.2rem;
      width: 7rem;
      height: 3rem;
      font-style: normal;
      font-weight: normal;
      background-color: #094063;
      color: #FFFFFF;
      border: 0;
      margin: 0 1rem; }
    .header__right .header__notif button {
      width: 2rem;
      height: 2rem; }
    .header__right .header__notif .notif {
      border: 0;
      background-color: #FFFFFF;
      color: #094063; }
    .header__right .header__search {
      width: 9rem;
      height: 3rem; }
      .header__right .header__search input {
        border-radius: 3px;
        width: 100%;
        height: 100%;
        border: 1px solid #094063;
        padding: 1rem; }
  .dashboard-card__body-text, .signup__body-text {
    font-size: 1.4rem; }
  .dashboard-card__title-text {
    font-size: 1.6rem; }
  .dashboard-control-item {
    font-size: 1.4rem; }
  .dashboard-item-count {
    font-size: 1.4rem;
    padding-right: 2rem; }
  .dashboard-control__button {
    font-size: 1.4rem;
    width: auto; }
  .dashboard-table-item {
    font-size: 1.4rem;
    padding: 2rem; }
  .profile-input {
    width: 100%; }
  .profile-form-item-wrapper {
    flex-direction: column; }
  .profile-input-wrap {
    margin-right: 0; }
  .dashboard-form__input {
    width: inherit; }
  .dashboard-form__btn {
    margin-top: 3rem; }
  .dashboard-input-wrap {
    width: 100%; }
  .profile-detail-wrap {
    flex-direction: column; }
  .profile-detail-wrap__layout {
    margin-left: 0; }
  .profile-section {
    flex-direction: column; }
  .modal-content {
    width: 90%; }
  .payment-freq-modal-wrap {
    flex-direction: column; }
    .payment-freq-modal-wrap div {
      margin-bottom: 2rem; }
  .dashboard-form-item-wrapper {
    flex-direction: column; }
  .dashboard-form__checklist-wrap {
    width: 100%; }
  .plan-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4rem;
    max-width: none; }
  .empty-state-btn-wrap {
    width: 100%;
    justify-content: space-around; }
  .overview-card {
    width: 100%; }
  .login_form {
    width: 80%;
    height: auto;
    padding: 2rem; }
    .login_form img {
      margin-bottom: 2rem; }
    .login_form .form {
      width: 95%;
      height: 100%; }
      .login_form .form input[type='text'],
      .login_form .form input[type='password'] {
        padding: 1.6rem 1rem; }
      .login_form .form input[type='submit'] {
        padding: 1.6rem;
        margin-top: 2.8rem; }
  .select-account-wrap {
    padding: 2rem;
    width: 100%; }
  .web-header__text {
    font-size: 3.2rem;
    line-height: 4rem; }
  .web-header__text_small {
    font-size: 1.6rem;
    line-height: 3rem; }
  .select-account-card {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap; }
    .select-account-card .dashboard-card {
      width: 15rem;
      height: 20rem;
      margin: 1rem;
      padding: 1rem; }
      .select-account-card .dashboard-card img {
        margin-bottom: 3rem; } }
